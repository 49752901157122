<template>
  <v-container v-if="profile">
    <v-row class="justify-center">
      <v-col cols="12" sm="6">
        <h5>Setting address</h5>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" sm="6">
        <base-address
          hideName
          showLocationIcon
          @saveAddress="saveAddress"
          :addressType="$route.params.type"
        ></base-address>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseAddress from "./BaseAddress";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseAddress
  },
  computed: {
    ...mapGetters({
      myProfile: "profile",
      userProfile: "userProfile",
      loading: "loading"
    }),
    profile() {
      if (this.$route.params.id) {
        return this.userProfile;
      }
      return this.myProfile;
    }
  },

  methods: {
    saveAddress(address) {
      console.log("Saving address", address);
      if (!this.profile.addresses) {
        this.profile.addresses = [];
      }
      let found = false;
      address.no = address.no || this.profile.addresses.length;
      this.profile.addresses.forEach((element, index) => {
        if (element.type === address.type) {
          found = true;
          this.profile.addresses[index] = address;
        }
      });
      console.log("Post update", this.profile.addresses);
      if (!found) {
        this.profile.addresses.push(address);
        console.warn("Could not find the address to update");
      }
      this.$store.dispatch("saveAddresses", {
        profile: this.profile,
        addresses: this.profile.addresses
      });
      this.$store.dispatch("setMessage", {
        title: "Saved",
        body: "Location updated"
      });
    }
  }
};
</script>

<style></style>
